const findName = name => author => author.userId === name;
const authorEdgesToList = authorEdges => authorEdges.map(edge => edge.node);

class AuthorModel {
  static hasAuthor(authorEdges, authorUserId) {
    const authorList = authorEdgesToList(authorEdges);
    return Boolean(authorList.find(findName(authorUserId)));
  }

  static getAuthor(authorEdges, authorUserId, fallbackId) {
    const authorList = authorEdgesToList(authorEdges);
    if (this.hasAuthor(authorEdges, authorUserId)) {
      return authorList.find(findName(authorUserId));
    }
    return authorList.find(findName(fallbackId));
  }
}

export default AuthorModel;
